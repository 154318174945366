var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
      _c("div", { staticClass: "row row-no-padding row-col-separator-xl" }, [
        _c("div", { staticClass: "col-md-12 col-lg-4 col-xl-4" }, [
          _c("div", { staticClass: "kt-widget1" }, [
            _c("div", { staticClass: "kt-widget1__item" }, [
              _vm._m(0),
              _c(
                "span",
                { staticClass: "kt-widget1__number kt-font-primary" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.randomVal("totalStudents"),
                      duration: 1250,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-widget1__item" }, [
              _vm._m(1),
              _c(
                "span",
                { staticClass: "kt-widget1__number kt-font-primary" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.randomVal("englishLanguageLearners"),
                      duration: 1250,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12 col-lg-4 col-xl-4" }, [
          _c("div", { staticClass: "kt-widget1" }, [
            _c("div", { staticClass: "kt-widget1__item" }, [
              _vm._m(2),
              _c(
                "span",
                { staticClass: "kt-widget1__number kt-font-primary" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.randomVal("everElls"),
                      duration: 1250,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-widget1__item" }, [
              _vm._m(3),
              _c(
                "span",
                { staticClass: "kt-widget1__number kt-font-primary" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.randomVal("neverElls"),
                      duration: 1250,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12 col-lg-4 col-xl-4" }, [
          _c("div", { staticClass: "kt-widget1" }, [
            _c("div", { staticClass: "kt-widget1__item" }, [
              _vm._m(4),
              _c(
                "span",
                { staticClass: "kt-widget1__number kt-font-primary" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.randomVal("totalSchools"),
                      duration: 1250,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-widget1__item" }, [
              _vm._m(5),
              _c(
                "span",
                { staticClass: "kt-widget1__number kt-font-primary" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.randomVal("totalLanguages"),
                      duration: 1250,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [
        _vm._v(" Total Students "),
      ]),
      _c("span", { staticClass: "kt-widget1__desc" }, [
        _vm._v("Earning the Biliteracy Seal"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [
        _vm._v(" English Language Learners "),
      ]),
      _c("span", { staticClass: "kt-widget1__desc" }, [
        _vm._v("10% of total seal earners"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [_vm._v(" Ever ELLs ")]),
      _c("span", { staticClass: "kt-widget1__desc" }, [
        _vm._v("16% of total seal earners"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [_vm._v(" Never ELLs ")]),
      _c("span", { staticClass: "kt-widget1__desc" }, [
        _vm._v("14% of total seal earners"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [
        _vm._v(" Total Schools "),
      ]),
      _c("span", { staticClass: "kt-widget1__desc" }, [
        _vm._v("Offering the Biliteracy Seal"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget1__info" }, [
      _c("h3", { staticClass: "kt-widget1__title" }, [
        _vm._v(" Total Languages "),
      ]),
      _c("span", { staticClass: "kt-widget1__desc" }, [
        _vm._v("In which seals were earned"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }