<template>
<div class="kt-portlet">
    <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-xl">
            <div class="col-md-12 col-lg-4 col-xl-4">
                <div class="kt-widget1">
                    <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">
                                Total Students
                            </h3>
                            <span class="kt-widget1__desc">Earning the Biliteracy Seal</span>
                        </div>
                        <span class="kt-widget1__number kt-font-primary">
                            <animated-number
                                :value="randomVal('totalStudents')"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                    <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">
                                English Language Learners
                            </h3>
                            <span class="kt-widget1__desc">10% of total seal earners</span>
                        </div>
                        <span class="kt-widget1__number kt-font-primary">
                            <animated-number
                                :value="randomVal('englishLanguageLearners')"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4">
                <div class="kt-widget1">
                    <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">
                                Ever ELLs
                            </h3>
                            <span class="kt-widget1__desc">16% of total seal earners</span>
                        </div>
                        <span class="kt-widget1__number kt-font-primary">
                            <animated-number
                                :value="randomVal('everElls')"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                    <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">
                                Never ELLs
                            </h3>
                            <span class="kt-widget1__desc">14% of total seal earners</span>
                        </div>
                        <span class="kt-widget1__number kt-font-primary">
                            <animated-number
                                :value="randomVal('neverElls')"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-4">
                <div class="kt-widget1">
                    <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">
                                Total Schools
                            </h3>
                            <span class="kt-widget1__desc">Offering the Biliteracy Seal</span>
                        </div>
                        <span class="kt-widget1__number kt-font-primary">
                            <animated-number
                                :value="randomVal('totalSchools')"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />

                        </span>
                    </div>
                    <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                            <h3 class="kt-widget1__title">
                                Total Languages
                            </h3>
                            <span class="kt-widget1__desc">In which seals were earned</span>
                        </div>
                        <span class="kt-widget1__number kt-font-primary">
                            <animated-number
                                :value="randomVal('totalLanguages')"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import AnimatedNumber from 'animated-number-vue';
import utilMixins from '../store/mixins/utilMixins';

export default Vue.extend({
    name: 'BiliteracySealStatistics',
    components: {
        AnimatedNumber,
    },
    mixins: [
        utilMixins,
    ],
    props: {
        threeColumns: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            base: {
                totalStudents: 7114,
                englishLanguageLearners: 736,
                everElls: 1136,
                neverElls: 997,
                totalSchools: 374,
                totalLanguages: 68,
            },
        };
    },
    computed: {
        schoolYear() {
            return this.$store.state.biliteracySeal.schoolYear;
        },
    },
    watch: {
        schoolYear() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        formatPoints(value) {
            return this.formatNumber(value);
        },
        randomVal(prop) {
            const { schoolYear } = this;
            const val = this.base[prop];
            const totalYears = 7;
            if (schoolYear === 2021) return val;
            const divisor = 2021 - schoolYear;
            const max = (val / totalYears) * (totalYears - divisor);
            const min = (val / totalYears) * (totalYears - divisor - 1);
            // console.log({ min, max });
            return randomIntFromInterval(min, max);
        },
        populate() {
            // asd
        },
    },
});

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
</script>

<style lang="scss">
.chartDiv {
    height: 500px;
    width: 100%;
}
</style>
